<template>
  <div>
    <vue-pdf-app
      v-show="pdf"
      :page-number="1"
      class="height-pdf"
      :pdf="pdf"
      file-name="FACTURA"
      :config="config"
      @pages-rendered="pagesRenderedHandler"
    >
      <template #toolbar-right-prepend="{}">
        <button
          @click="printPdf"
          type="button"
          title="Imprimir"
          style="padding: 8px 6px 0; font-size: 12px"
          class="prepend print hiddenMediumView vue-pdf-app-icon print-button"
        ></button>
      </template>
      <template #secondary-toolbar-prepend="{}">
        <button
          title="Imprimir"
          type="button"
          class="secondaryToolbarButton print vue-pdf-app-icon print-button"
          @click="printPdf"
        >
          <span>Imprimir</span>
        </button>
      </template>
    </vue-pdf-app>
    <b-card class="container text-center" v-if="!loading">
      <div v-if="venta">
        <h4>ESTADO: {{ venta.estado }}</h4>
        <b-button variant="outline-dark" size="sm" :href="venta.urlSiat">
          VERIFICAR EN SIAT
        </b-button>
      </div>
      <div v-else>
        <h4>NO EXISTE LA FACTURA</h4>
      </div>
    </b-card>
  </div>
</template>
<script>
import printJS from "print-js";
import axios from "axios";
import { BCard, BButton } from "bootstrap-vue";
import "vue-pdf-app/dist/icons/main.css";
export default {
  components: { BCard, BButton },
  directives: {},
  data() {
    return {
      cuf: "",
      pdf: "",
      pdfBase64: "",
      venta: "",
      loading: false,
      config: {
        sidebar: false,
        toolbar: {
          toolbarViewerLeft: {
            findbar: false,
          },
          toolbarViewerRight: {
            presentationMode: false,
            openFile: false,
            print: false,
            viewBookmark: false,
          },
        },
        secondaryToolbar: {
          secondaryPresentationMode: false,
          secondaryOpenFile: false,
          secondaryPrint: false,
          secondaryViewBookmark: false,
          firstPage: false,
          lastPage: false,
          pageRotateCw: false,
          pageRotateCcw: false,
          cursorSelectTool: false,
          cursorHandTool: false,
          scrollVertical: false,
          scrollHorizontal: false,
          scrollWrapped: false,
          spreadNone: false,
          spreadOdd: false,
          spreadEven: false,
        },
      },
    };
  },
  created() {
    if (Object.keys(this.$route.query).length === 0) {
      this.cuf = "";
    } else {
      this.cuf = this.$route.query.cuf;
      console.log(this.cuf);
      this.getFacturaCuf();
    }
  },
  computed: {},
  watch: {
    $route: function () {
      if (Object.keys(this.$route.query).length === 0) {
        this.cuf = "";
      } else {
        this.cuf = this.$route.query.cuf;
        console.log(this.cuf);
        this.getFacturaCuf();
      }
    },
  },

  methods: {
    async getFacturaCuf() {
      this.loading = true;
      await axios
        .get(
          `${process.env.VUE_APP_FACT_REST}/facturacion/ventas/computarizadas/cuf/${this.cuf}`,
          {
            headers: {
              "Content-Type": "application/json",
              apikey: `${process.env.VUE_APP_APIKEY}`,
            },
          }
        )
        .then((res) => {
          this.loading = false;
          this.pdf =
            "data:application/pdf;base64," +
            res.data.facturaDocumento +
            "#toolbar=0";
          this.pdfBase64 = res.data.facturaDocumento;
          this.venta = res.data.Venta;
        })
        .catch((err) => {
          this.loading = false;
          this.pdf = "";
          this.pdfBase64 = "";
          this.venta = "";
          console.log(err);
        });
    },
    printPdf() {
      printJS({
        printable: this.pdfBase64,
        type: "pdf",
        base64: true,
      });
    },
  },
};
</script>
<style lang="scss">
.height-pdf {
  height: calc(var(--vh, 1vh) * 100 - 6.5rem);
  .pdfViewer .page > * {
    height: 100% !important;
  }
}
</style>